//======================================================================
// variable
//======================================================================

// imgディレクトリまでのパス
$img_dir: '../img';

// コンテンツ幅
$content_width: 1200px;

// SP時の左右の余白（片方）
$sp_side_padding: 20px;

//=================================
// breakpoint
//=================================
// $pc_bp以上がPC表示
$pc_bp: 1025px;

// $sp_bp以下がSP表示
$sp_bp: 767px;

//=================================
// font
//=================================
// $font_1: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', YuGothic, sans-serif;
$font_1: YakuHanJP, 'Noto Sans JP', source-han-sans-japanese, '游ゴシック',
  YuGothic, sans-serif;

$fontEn: YakuHanJP, 'Jost', source-han-sans-japanese sans-serif;
//=================================
// color
//=================================
$black: #000;

$white: #fff;

$gray: #f1f3f8;

$blue: #0e318d;

$color_1: #2c2c2c;

$color_2: #4275a3;

$color_3: #6894ba;

$color_4: #f9bd2c;

$color_5: #000;

//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(0.25, 0.25, 0.75, 0.75);
$ease: cubic-bezier(0.25, 0.1, 0.25, 1);
