//======================================================================
// mixin
//======================================================================

//=================================
// clearfix
//=================================
@mixin cf {
  &:after {
    display: block;
    clear: both;
    content: '';
  }
}

//=================================
// プレースホルダーのカラー変更
//=================================
@mixin placeholder($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

//=================================
// media query
//=================================
@mixin mq($min_width, $max_width) {
  @media screen and (min-width: $min_width + px) and (max-width: $max_width + px) {
    @content;
  }
}
@mixin mq_min($width) {
  @media screen and (min-width: $width + px) {
    @content;
  }
}
@mixin mq_max($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

// device
@mixin pc {
  @media screen and (min-width: $pc_bp) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: $sp_bp) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: $sp_bp + 1px) and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_tab {
  @media screen and (min-width: $sp_bp + 1px) {
    @content;
  }
}
@mixin sp_tab {
  @media screen and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_content {
  @media screen and (min-width: $pc_bp) and (max-width: $content_width) {
    @content;
  }
}

//=================================
// css hack
//=================================
// Chrome(and Safari, Opera)
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    @content;
  }
}
// FireFox
@mixin firefox {
  _:lang(x)::-moz-placeholder,
  & {
    @content;
  }
}
// Edge
@mixin edge {
  _:lang(x)::-ms-,
  & {
    @content;
  }
}
// IE(11)
@mixin ie {
  _:-ms-lang(x)::-ms-backdrop,
  & {
    @content;
  }
}
// Safari
@mixin safari {
  _:lang(x) + _:-webkit-full-screen-document,
  & {
    @content;
  }
}
